import { FC, useCallback } from "react";
import Form from "pages/grant/common/form";
import useGrants from "hooks/useGrants";
import { confirm } from "components/confirmation";
import { stringOrUndefined } from "helpers/date";

export type Field = Record<string, any | Record<any, any>>;

const ShowGrantPage: FC = () => {
  const {
    updateGrantSettings,
    updateGrantEmailSettings,
    errors,
    grant,
    loading,
  } = useGrants();

  const onSubmit = useCallback(
    (data: any, cb?: () => void) => {
      if (
        grant.startDate &&
        grant.endDate &&
        (data.startDate !== stringOrUndefined(grant.startDate) ||
          data.endDate !== stringOrUndefined(grant.endDate))
      ) {
        confirm({
          title: "The grant duration will be adjusted",
          text: "Increasing the grant duration will result in the addition of extra blank year(s). Decreasing the duration will lead to the irretrievable deletion of compensation data for the last year(s)",
          type: "error",
          icon: "info-circle",
          onConfirm: () => {
            updateGrantSettings(grant.id, data, cb);
          },
        });
        return;
      }
      updateGrantSettings(grant.id, data, cb);
    },
    [grant, updateGrantSettings]
  );

  const onSubmitEmail = useCallback(
    (data: any, cb?: () => void) => {
      updateGrantEmailSettings(grant.id, data, cb);
    },
    [grant, updateGrantEmailSettings]
  );

  return (
    <Form
      onSubmitEmail={onSubmitEmail}
      errors={errors}
      grant={grant}
      loading={loading}
      onSubmit={onSubmit}
    />
  );
};

const EditGrant: FC = () => {
  const { grant } = useGrants();

  return <ShowGrantPage key={`${grant.id}-${grant.updatedAt}`} />;
};

export default EditGrant;
