import { useCallback, useState } from "react";

const PAGE_SIZE = 10;
export function usePagination<T>(items: T[]) {
  const [page, setPage] = useState(0);

  const next = useCallback(() => {
    setPage((p) => p + 1);
  }, [setPage]);

  const previous = useCallback(() => {
    setPage((p) => p - 1);
  }, [setPage]);

  return {
    pageItems: items.slice(page * PAGE_SIZE, page * PAGE_SIZE + PAGE_SIZE),
    page: page + 1,
    hasPrevious: page > 0,
    hasNext: page * PAGE_SIZE + PAGE_SIZE < items.length,
    next,
    previous,
  };
}
