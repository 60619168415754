import { Switch, Route } from "react-router-dom";
import { useEffect } from "react";
import useEnums from "hooks/useEnums";
import useAuth from "hooks/useAuth";
import ProtectedRoute, { AuthRoute } from "containers/protectedRoute";
import Grant from "pages/grant";
import Home from "pages/home";
import SignIn from "pages/auth/signin";
import SignUp from "pages/auth/signUp";
import ConfirmEmail from "pages/auth/signUp/confirm";
import Forgot from "pages/auth/forgot";
import Recovery from "pages/auth/recovery";
import ForgotSuccess from "pages/auth/forgot-success";
import Settings from "pages/settings";
import CompleteSubAward from "./pages/completeSubaward";
import { useLocation } from "react-router";
import { InvitePage } from "pages/invite";

const AppRoutes = () => {
  const location = useLocation();
  const { me, token } = useAuth();
  const { getEnums } = useEnums();

  useEffect(() => {
    getEnums();
    me();
  }, []);

  function removeTextNode() {
    const bodyNodes = document.body.childNodes;
    for (let i = 0; i < bodyNodes.length; i++) {
      const node = bodyNodes[i];
      // @ts-ignore
      if (
        node.nodeType === Node.TEXT_NODE &&
        node.textContent?.trim().includes("Intercom")
      ) {
        // @ts-ignore
        node.parentNode.removeChild(node);
        return;
      }
    }
  }

  useEffect(() => {
    const observer = new MutationObserver((mutationsList) => {
      for (let mutation of mutationsList) {
        if (mutation.type === "childList") {
          removeTextNode();
        }
      }
    });

    observer.observe(document.body, { childList: true, subtree: true });

    // Initial check in case the script is already present in the DOM
    removeTextNode();
  }, []);

  useEffect(() => {
    const pagePath = location.pathname + location.search;

    // @ts-ignore
    window.dataLayer.push({
      event: "pageview",
      page: pagePath,
    });
  }, [location]);

  if (!token) {
    // @ts-ignore
    if (window.Intercom) {
      // @ts-ignore
      window.Intercom("shutdown");
    }
  }

  return (
    <Switch>
      <Route
        path="/complete-subaward/:token"
        component={CompleteSubAward}
      ></Route>
      <Route
        path="/login"
        render={() => (
          <AuthRoute>
            <SignIn />
          </AuthRoute>
        )}
      ></Route>
      <Route
        path="/password-change/:hash"
        render={() => (
          <AuthRoute>
            <Recovery />
          </AuthRoute>
        )}
      ></Route>
      <Route
        path="/sign-up"
        render={() => (
          <AuthRoute>
            <SignUp />
          </AuthRoute>
        )}
      ></Route>
      <Route
        path="/confirm-email/:hash"
        render={() => (
          <AuthRoute>
            <ConfirmEmail />
          </AuthRoute>
        )}
      ></Route>
      <Route path="/forgot" render={() => <Forgot />}></Route>
      <Route
        path="/forgot-success"
        render={() => (
          <AuthRoute>
            <ForgotSuccess />
          </AuthRoute>
        )}
      ></Route>
      <Route path="/grants">
        <ProtectedRoute>
          <Grant />
        </ProtectedRoute>
      </Route>
      <Route path="/settings">
        <ProtectedRoute>
          <Settings />
        </ProtectedRoute>
      </Route>
      <Route
        path="/"
        exact
        render={() => (
          <ProtectedRoute>
            <Home />
          </ProtectedRoute>
        )}
      ></Route>
      <Route path="/invite/:shareKey" render={() => <InvitePage />}></Route>
    </Switch>
  );
};

export default AppRoutes;
