import client from "helpers/apiClient";
import { useQuery } from "react-query";

export function useGrantSubscription(grantId: string) {
  const { data } = useQuery({
    queryKey: ["valid-grant", grantId],
    queryFn: async () => {
      const result = await client.get("/grant-tokens/valid/" + grantId);
      return result.data.isValid;
    },
  });

  return !!data;
}
