import { useMutation, useQuery } from "react-query";
import { useHistory, useLocation, useParams } from "react-router-dom";
import client from "helpers/apiClient";
import toast from "components/toast";

import headerIcon from "./headericon.png";
import tokenIcon from "./Vector.png";

import styles from "./invite.module.css";
import useAuth from "hooks/useAuth";

type Invitation = {
  shareKey: string;
  numberOfPermissions: number;
  validUntil: string;
  isInfinitePermission: boolean;
};

export function InvitePage() {
  const auth = useAuth();
  const location = useLocation();
  const history = useHistory();

  const params = useParams<Record<string, string>>();
  const shareKey = params.shareKey;
  const { data, isLoading } = useQuery({
    queryKey: ["invitation", shareKey],
    queryFn: async () => {
      try {
        const response = await client.get("/grant-invitations/" + shareKey);

        return response.data as Invitation;
      } catch (e) {
        return null;
      }
    },
  });

  const acceptInvitation = useMutation({
    mutationFn: async () => {
      try {
        await client.post("/grant-invitations/accept-invite/" + shareKey);
        return true;
      } catch (e) {
        return false;
      }
    },
    onSuccess: (isSuccessful) => {
      if (!isSuccessful) {
        toast.error("Invitation already accepted");
      } else {
        toast.success("Invitation accepted");
        setTimeout(() => {
          history.push("/");
        }, 3000);
      }
    },
  });

  function handleAcceptInvitation() {
    if (!auth.user.id) {
      history.push("/login?url=" + encodeURIComponent(location.pathname));
      return;
    }
    acceptInvitation.mutateAsync();
  }

  if (isLoading) {
    return null;
  }

  if (!data) {
    return (
      <div className={styles.pageCenter}>
        <div>
          <div className={styles.header}>
            <img src={headerIcon} />
          </div>
          <h1>Sorry Invitation is expired or invalid!</h1>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.pageCenter}>
      <div style={{ width: "30em", margin: "0 auto" }}>
        <div className={styles.header}>
          <img src={headerIcon} />
        </div>
        <h1>Welcome! You've Got Tokens</h1>
        <p
          style={{
            width: "25em",
            margin: "0 auto",
            color: "#475467",
            textAlign: "center",
          }}
        >
          You received{" "}
          <strong>
            {data.isInfinitePermission ? "unlimited" : data.numberOfPermissions}
            <img className={styles.tokenIcon} src={tokenIcon} />
            tokens
          </strong>{" "}
          via the Budget Mentor platform. Click the button below to add them to
          your balance.
        </p>
        <button onClick={handleAcceptInvitation} className={styles.claimButton}>
          Claim tokens
        </button>
      </div>
      <div className={styles.claimText}>
        By clicking "Claim Tokens," you’ll be directed to sign up or log in.
      </div>
    </div>
  );
}
