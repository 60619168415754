import { useQuery } from "react-query";
import client from "helpers/apiClient";

import styles from "./InvitationDropdown.module.css";
import { ReactNode, useCallback, useEffect, useState } from "react";

import tokenIcon from "./tokenIcon.png";
import chevronIcon from "./chevron-selector-vertical.png";
import { History, Notebook, Share } from "lucide-react";
import { InvitationModal } from "../invitationModal/InvitationModal";
import { InvitationManageModal } from "../invitationManageModal/InvitationManageModal";
import { InvitationHistoryModal } from "../invitationHistoryModal/InvitationHistoryModal";

type GrantToken = {};

export function InvitationDropdown() {
  const [open, setOpen] = useState(false);
  const { data: tokens } = useQuery({
    queryKey: ["tokens", "me"],
    queryFn: async () => {
      const response = await client.get("/grant-tokens/me");
      return response.data as GrantToken[];
    },
  });

  const [invitationOpen, setInvitationOpen] = useState(false);
  const [invitationManageOpen, setInvitationManageOpen] = useState(false);
  const [invitationHistoryOpen, setInvitationHistoryOpen] = useState(false);

  const handleClick = useCallback(
    (event: any) => {
      const inElem = event.target?.closest(`.invitation-dropdown`);
      if (open && !inElem) {
        setOpen(false);
      }
      return;
    },
    [open]
  );

  useEffect(() => {
    document.addEventListener("click", handleClick, true);
    return () => {
      document.removeEventListener("click", handleClick, true);
    };
  }, [open, handleClick]);

  return (
    <div style={{ position: "relative" }} className="invitation-dropdown">
      <button className={styles.dropdown} onClick={() => setOpen(!open)}>
        <img src={tokenIcon} alt="token" />
        <span>{tokens?.length ? tokens.length : ""} Grants</span>
        <img src={chevronIcon} alt="chevron" />
      </button>
      {open && (
        <ul className={styles.list}>
          <DropdownItem
            label="Share"
            icon={<Share className={styles.icon} />}
            onClick={() => setInvitationOpen(true)}
          />
          <DropdownItem
            label="Share History"
            icon={<History className={styles.icon} />}
            onClick={() => setInvitationHistoryOpen(true)}
          />
          <DropdownItem
            label="Manage Grants"
            icon={<Notebook className={styles.icon} />}
            onClick={() => setInvitationManageOpen(true)}
          />
        </ul>
      )}
      <InvitationModal
        open={invitationOpen}
        onOpenChanged={setInvitationOpen}
      />
      <InvitationManageModal
        open={invitationManageOpen}
        onOpenChanged={setInvitationManageOpen}
      />
      <InvitationHistoryModal
        open={invitationHistoryOpen}
        onOpenChanged={setInvitationHistoryOpen}
      />
    </div>
  );
}

function DropdownItem({
  onClick,
  label,
  icon,
}: {
  onClick: () => void;
  label: string;
  icon: ReactNode;
}) {
  return (
    <li role="button" onClick={onClick} className={styles.listItem}>
      <div className={styles.listItemContent}>
        {icon}
        <div className={styles.link}>{label}</div>
      </div>
    </li>
  );
}
