import { FC, useCallback, useState, useRef, useMemo } from "react";
import Form from "pages/grant/common/form";
import { useParams } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import useAuth from "hooks/useAuth";
import { isEmpty } from "lodash";
import useGrants from "hooks/useGrants";
import ProfileForm from "containers/forms/profile";
import Typo from "components/typo";
import { Button, Input } from "components/form";
import Modal from "components/modal";
import styles from "./edit.module.css";
import * as T from "hooks/requestTypes";
import { stringOrUndefined } from "helpers/date";
import { confirm } from "components/confirmation";
import { trackUserAction } from "helpers/trackUserActions";
import Help from "../../../../components/help";

export type Field = Record<string, any | Record<any, any>>;

const CreateGrantPage: FC = () => {
  const { user, updateMe, updateOrganization, ...auth } = useAuth();
  const {
    updateGrantAuthorSettings,
    updateGrantEmailSettings,
    updateGrantSettings,
    errors,
    grant,
    loading,
  } = useGrants();
  const params: Record<string, string> = useParams();
  const [show, onChangeShow] = useState<boolean>(true);
  const [step, onChangeStep] = useState<number>(0);
  const modal = useRef<HTMLDivElement | null>(null);
  const methods = useForm();

  const [personal, onChangePersonal] = useState<T.UpdateUserInfo>(() => {
    const author = grant.author.firstName ? grant.author : user;
    return {
      calculateAverageBudget: author.calculateAverageBudget ?? "",
      firstName: author.firstName ?? "",
      lastName: author.lastName ?? "",
      yearSalary: author.yearSalary ? author.yearSalary : null,
      yearlyMonthsSalaryCompensation: author.yearlyMonthsSalaryCompensation
        ? author.yearlyMonthsSalaryCompensation
        : null,
      oneMonthCompensationAmount: author.oneMonthCompensationAmount
        ? author.oneMonthCompensationAmount
        : null,
      birthdate: author.birthdate ? new Date(author.birthdate) : undefined,
    };
  });
  const submitUser = methods.handleSubmit(() => {
    //@ts-ignore
    updateMe(personal);
  });

  const handleChangePersonalData = useCallback(
    (field: Field) => {
      onChangePersonal({
        ...personal,
        ...field,
      });
    },
    [personal, onChangePersonal]
  );

  const handleSubmitStep = methods.handleSubmit(() => {
    updateGrantAuthorSettings(params.id as string, personal, () => {
      onChangeShow(false);
    });
    trackUserAction("Successfully started a new grant");
  });

  const handlePrevStep = useCallback(() => {
    onChangeStep(0);
    if (modal.current) modal.current.scrollIntoView({ behavior: "smooth" });
  }, [modal]);

  const onSubmit = useCallback(
    (data: T.GrantBasicInfo, cb?: () => void) => {
      const datesExist = grant.startDate && grant.endDate;
      if (
        datesExist &&
        (data.startDate !== stringOrUndefined(grant.startDate) ||
          data.endDate !== stringOrUndefined(grant.endDate))
      ) {
        confirm({
          title: "The grant duration will be adjusted",
          text: "Increasing the grant duration will result in the addition of extra blank year(s). Decreasing the duration will lead to the irretrievable deletion of compensation data for the last year(s)",
          type: "error",
          icon: "info-circle",
          onConfirm: () => {
            updateGrantSettings(grant.id, data, cb);
          },
        });
        return;
      }
      updateGrantSettings(grant.id, data, cb);
    },
    [grant, updateGrantSettings]
  );

  const onSubmitEmail = useCallback(
    (data: T.GrantBasicInfo, cb?: () => void) => {
      updateGrantEmailSettings(grant.id, data, cb);
    },
    [grant, updateGrantEmailSettings]
  );

  const title = useMemo(() => {
    if (step === 0) return "Personal information";
    return "Organization information";
  }, [step]);

  const subtitle = useMemo(() => {
    if (step === 0)
      return "Please verify and update your information so that we can ensure that your data on this grant is compliant with government guidelines.";
    return "Please verify and update your information so that we can ensure that your data on this grant is compliant with government guidelines.";
  }, [step]);

  const onCancel = Boolean(grant.author.firstName && grant.organization.name)
    ? () => onChangeShow(false)
    : undefined;

  return (
    <div>
      <Form
        errors={errors}
        key={grant.updatedAt}
        onSubmitEmail={onSubmitEmail}
        grant={grant}
        loading={loading}
        onSubmit={onSubmit}
      />
      <Modal
        title={title}
        wide
        visible={show}
        subtitle={subtitle}
        onCancel={onCancel}
      >
        <div className={styles.modal} ref={modal}>
          <Typo className={styles.tabTitle} semi>
            Personal information
          </Typo>
          <FormProvider {...methods}>
            <ProfileForm
              embed
              short
              user={user}
              errors={isEmpty(errors) ? auth.errors : errors}
              onSubmit={() => submitUser()}
              data={personal}
              onChange={handleChangePersonalData}
            >
              <>
                <Typo type="div" className={styles.tabTitle} semi>
                  <>
                    Budget Inflation Rate
                    <Help>
                      <div className={styles.smallHelp}>
                        For most costs, NSF requires that inflation in the
                        amount of 4% per year be applied after Year 1. This will
                        be automatically calculated for you when necessary.
                      </div>
                    </Help>
                  </>
                </Typo>
                <Input
                  name="inflationRate"
                  value={4}
                  className={styles.fullInput}
                  disabled
                  readOnly
                  label="Inflation Rate (%)"
                  placeholder="Inflation rate, %"
                />
                <div className={styles.personalInfoWrapper}>
                  <div className={styles.personalInfo}>
                    <Typo className={styles.greenText}>
                      Updating this information and saving this to Account
                      settings will NOT affect any of the values or numbers that
                      you have already used on prior grants
                    </Typo>
                    <Button
                      size="lg"
                      onClick={submitUser}
                      loading={auth.loading}
                      htmlType="submit"
                      name="update-account-submit"
                    >
                      Update Account settings info
                    </Button>
                  </div>
                </div>
              </>
            </ProfileForm>
            <div className={styles.commonFooter}>
              <div className={styles.footerBtns}>
                {step > 0 ? (
                  <Button
                    htmlType="button"
                    preIcon="arrow-narrow-left"
                    type="bordered"
                    onClick={handlePrevStep}
                    name="save"
                    size="lg"
                  >
                    Previous step
                  </Button>
                ) : null}
                <Button
                  htmlType="button"
                  loading={loading}
                  onClick={handleSubmitStep}
                  postIcon="arrow-narrow-right"
                  name="save"
                  size="lg"
                >
                  Save and continue
                </Button>
              </div>
            </div>
          </FormProvider>
        </div>
      </Modal>
    </div>
  );
};

const CreateGrant: FC = () => {
  const { grant } = useGrants();
  return grant.id ? <CreateGrantPage key={grant.id} /> : null;
};

export default CreateGrant;
