import React, {
  FC,
  ReactElement,
  useCallback,
  useLayoutEffect,
  createRef,
  useEffect,
  useState,
  useMemo,
  ChangeEvent,
  useContext,
} from "react";
import cx from "classnames";
import { debounce } from "lodash";
import { trackUserAction } from "helpers/trackUserActions";
import Header from "containers/header";
import InfoSidebar from "containers/infoSidebar";
import { Link, useParams } from "react-router-dom";
import Modal, { VideoModal } from "components/modal";
import Tip from "components/tip";
import Icon from "components/icon";
import { Button, Form, Input } from "components/form";
import Typo from "components/typo";
import logo from "images/logo.svg";
import logoXS from "images/logo_xs.svg";
import useUI from "hooks/useUI";
import useGrants from "hooks/useGrants";
import preview from "images/preview.png";
import styles from "./paper.module.css";
import Step from "./step";
import { Errors } from "helpers/errors";
import { Stat, StatGrant } from "store/grants/types";
import useContextMenu from "context/useContextMenu";
import useUrl from "hooks/useUrl";
import BudgetBar from "../common/wrapper/bar";
import Settings from "./card/settings";

type Props = {
  children?: ReactElement;
};

export type StepType = {
  icon: string;
  label: string;
  step: string;
  open: boolean;
  count: number;
  list: StatGrant[];
};

export type PartialStepType = {
  icon: string;
  label: string;
  step: string;
};

const Paper: FC<Props> = ({ children }) => {
  const {
    minimizedSidebar,
    showPaywall,
    onHidePaywall,
    showSettingsModal,
    onHideSettingsModal,
    openedSteps,
    onChangeOpenedSteps,
    infoSidebar,
    changeSidebarWidth,
    maximizeSidebar,
    sidebarWidth,
  } = useUI();
  const contextMenu = useContext(useContextMenu);
  const { grantId, category, subCategory } = useUrl();
  const { getStats, clearEditableOne, editable, stats, grant, getList } =
    useGrants();
  const [showFeedback, onChangeShowFeedback] = useState<boolean>(false);
  const [dragging, onChangeDragging] = useState<boolean>(false);
  const [showVideo, onChangeShowVideo] = useState<boolean>(false);
  const [search, onChangeSearch] = useState<string | undefined>(undefined);
  const [clickSearch, onChangeClickSearch] = useState<boolean>(false);
  const searchRef = createRef<HTMLInputElement>();
  const url = useUrl();

  const makeDebouncedSearch = useCallback(
    debounce(
      (search: string) => {
        if (search.length > 2) {
          getStats(search);
        } else {
          getStats();
        }
      },
      800,
      { trailing: true }
    ),
    []
  );

  const noSearchError: Errors = useMemo(() => {
    const allGrantsCount = stats.reduce(
      (result: number, item: Stat) => result + item.count,
      0
    );
    const notFound = Boolean(
      allGrantsCount < 1 && search && search?.length > 2
    );
    if (notFound) {
      return {
        search: ["No grants found"],
      };
    }
    return { search: [] };
  }, [search, stats]);

  useEffect(() => {
    getStats();
  }, []);

  useLayoutEffect(() => {
    if (searchRef.current && clickSearch) searchRef.current.focus();
  }, [maximizeSidebar, clickSearch, searchRef]);

  const onClickSearchBtn = useCallback(() => {
    maximizeSidebar();
    onChangeClickSearch(true);
  }, [maximizeSidebar]);

  const onMouseMove = useCallback(
    (event: MouseEvent) => {
      if (dragging) changeSidebarWidth(event.screenX);
    },
    [dragging, changeSidebarWidth]
  );

  const onResizerMouseDown = useCallback(() => {
    onChangeDragging(true);
  }, []);

  const onResizerMouseUp = useCallback(() => {
    onChangeDragging(false);
  }, []);

  useEffect(() => {
    window.addEventListener("mousemove", onMouseMove);
    window.addEventListener("mouseup", onResizerMouseUp);
    return () => {
      window.removeEventListener("mousemove", onMouseMove);
      window.removeEventListener("mouseup", onResizerMouseUp);
    };
  }, [dragging, onResizerMouseUp, onMouseMove]);

  const handleChangeSearch = useCallback(
    (search: string) => {
      onChangeSearch(search);
      makeDebouncedSearch(search);
    },
    [makeDebouncedSearch]
  );

  const onShowVideo = useCallback(() => {
    onChangeShowVideo(true);
    trackUserAction("Clicks to Watch Explainer Video");
  }, []);

  const getStep = useCallback(
    (step: string): Stat | undefined => {
      return stats.find((item: Stat) => item.step === step);
    },
    [stats]
  );

  const steps: StepType[] = useMemo(() => {
    const searchInProgress = search && search?.length > 0;
    const list = [
      {
        icon: "line-chart-up-03",
        label: "In progress",
        step: "inProgress",
      },
      {
        icon: "hourglass-03",
        label: "Pending organization approval",
        step: "pendingOrganizationApproval",
      },
      {
        icon: "check-done-01",
        label: "Pending NSF approval",
        step: "pendingNsfApproval",
      },
      {
        icon: "refresh-ccw-01",
        label: "Revise and Resubmit",
        step: "reviseResubmit",
      },
      {
        icon: "check-square-broken",
        label: "Accepted",
        step: "accepted",
      },
      {
        icon: "archive",
        label: "Achieved",
        step: "hidden",
      },
    ];
    return list.map((item: PartialStepType): StepType => {
      const stepFromList: Stat | undefined = getStep(item.step);
      const open = Boolean(searchInProgress && (stepFromList?.count || 0) > 0);
      const grantInParams = Boolean(
        stepFromList?.grants.find((item: StatGrant) => item.id === grantId)
      );
      return {
        ...item,
        open: open || grantInParams,
        count: stepFromList?.count || 0,
        list: stepFromList?.grants || [],
      };
    });
  }, [getStep, search, grantId]);

  const handleOpenOneStep = useCallback(
    (idx: number, step: string) => {
      const newOpenedList = openedSteps.includes(idx) ? [] : [idx];
      if (newOpenedList.length > 0) {
        getList(undefined, step, () => {
          onChangeOpenedSteps(newOpenedList);
          if (minimizedSidebar && newOpenedList.includes(idx))
            maximizeSidebar();
        });
        return;
      }
      onChangeOpenedSteps(newOpenedList);
      if (minimizedSidebar && newOpenedList.includes(idx)) maximizeSidebar();
    },
    [openedSteps, getList, minimizedSidebar, maximizeSidebar]
  );

  const handleCloseSettings = useCallback(() => {
    onHideSettingsModal();
    clearEditableOne();
  }, [onHideSettingsModal, clearEditableOne, contextMenu]);

  return (
    <div className={styles.wrapper}>
      <div
        style={{ width: sidebarWidth }}
        className={cx(styles.sidebarWrapper, {
          [styles.minimizeSidebar]: minimizedSidebar,
          [styles.blockScroll]: contextMenu.show,
        })}
      >
        <Link to="/grants" className={styles.logoWrapper}>
          <img src={logo} className={styles.logo} alt="logo" />
          <img src={logoXS} className={styles.logoXS} alt="logo" />
        </Link>

        <div className={styles.header}>
          {minimizedSidebar ? (
            <Tip
              text="Grant search"
              className={styles.searchBtnTip}
              when={sidebarWidth < 100}
            >
              <Button
                onClick={onClickSearchBtn}
                className={styles.searchBtn}
                full
                name="search"
                type="ghost"
                preIcon="search-lg"
              />
            </Tip>
          ) : null}
          <Form>
            <Input
              onBlur={() => onChangeClickSearch(false)}
              preIcon="search-lg"
              refProp={searchRef}
              value={search}
              errors={noSearchError.search}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                handleChangeSearch(event.target.value)
              }
              className={cx(styles.search, {
                [styles.withError]: Boolean(noSearchError.search),
              })}
              name="add-grant"
              placeholder="Grant search"
            />
          </Form>
        </div>
        <div
          onMouseDown={onResizerMouseDown}
          onMouseUp={onResizerMouseUp}
          className={cx(styles.draggable, { [styles.dragging]: dragging })}
        />

        <div className={styles.steps}>
          <Typo type="h6" semi className={styles.stepsTitle} size={14}>
            Steps
          </Typo>
          <ul className={styles.list}>
            {steps.map((item: StepType, idx: number) => (
              <li
                key={idx}
                className={cx(styles.step, {
                  [styles.shortStep]: sidebarWidth < 370,
                })}
              >
                <Step
                  item={item}
                  sidebarWidth={sidebarWidth}
                  opened={openedSteps.includes(idx) || item.open}
                  onOpen={() =>
                    item.count > 0
                      ? handleOpenOneStep(idx, item.step)
                      : undefined
                  }
                  search={search}
                />
              </li>
            ))}
          </ul>
        </div>

        <div className={cx(styles.footer, styles.greenFooter)}>
          <a
            href="https://feedback.budget-mentor.com/"
            target="_blank"
            rel="noreferrer"
            className={cx(styles.greenFooterTitle, styles.footerTitle)}
            onClick={() => trackUserAction("Clicks Link to Leave Feedback")}
          >
            <>
              <span className={styles.footerIcon}>💙</span> Feedback & Bug
              Reporting
            </>
          </a>
          <a
            href="https://community.budget-mentor.com/"
            target="_blank"
            rel="noreferrer"
            className={cx(
              styles.greenFooterTitle,
              styles.last,
              styles.footerTitle
            )}
            onClick={() =>
              trackUserAction("Clicks Link to visit Help Community")
            }
          >
            <>
              <span className={styles.footerIcon}>🌐</span> Help Community
            </>
          </a>
        </div>

        <div
          className={cx(styles.footer, styles.greenFooter, styles.mobileFooter)}
        >
          <Tip
            text="Feedback & Bug Reporting"
            className={cx(styles.footerTip, {
              [styles.showTip]: sidebarWidth < 100,
            })}
            when={sidebarWidth < 100}
          >
            <a
              href="https://feedback.budget-mentor.com/"
              target="_blank"
              rel="noreferrer"
            >
              <Icon
                icon="heart"
                size={24}
                className={cx(styles.minimizedHelp, styles.greenHelp)}
              />
            </a>
          </Tip>
        </div>
        <div
          className={cx(styles.footer, styles.greenFooter, styles.mobileFooter)}
        >
          <Tip
            text="Help Community"
            className={cx(styles.footerTip, {
              [styles.showTip]: sidebarWidth < 100,
            })}
            when={sidebarWidth < 100}
          >
            <a
              href="https://community.budget-mentor.com/"
              target="_blank"
              rel="noreferrer"
            >
              <Icon
                icon="globe-01-1"
                size={24}
                className={cx(styles.minimizedHelp, styles.greenHelp)}
              />
            </a>
          </Tip>
        </div>

        <div className={styles.footer}>
          <Typo bold className={styles.footerTitle}>
            Introduction to Budget Mentor
          </Typo>
          <button className={styles.preview} onClick={onShowVideo}>
            <img src={preview} alt="preview" draggable={false} />
          </button>
          <Tip
            text="Introduction to Budget Mentor"
            className={cx(styles.footerTip, {
              [styles.showTip]: sidebarWidth < 100,
            })}
            when={sidebarWidth < 100}
          >
            <Icon
              icon="play"
              onClick={onShowVideo}
              size={24}
              className={styles.minimizedHelp}
            />
          </Tip>
        </div>
      </div>
      <div style={{ paddingLeft: sidebarWidth }} className={styles.content}>
        <Header short />
        <div
          className={styles.childContainer}
          style={{ paddingRight: infoSidebar ? 360 : 0 }}
        >
          {grant.id === url.grantId ? <BudgetBar /> : null}
          <div className={styles.childWrapper}>{children}</div>
        </div>
        <InfoSidebar key={`${grantId}-${category}-${subCategory}`} />
      </div>
      <VideoModal
        visible={showVideo}
        video="/static/intro.webm"
        onCancel={() => onChangeShowVideo(false)}
      />
      <Modal
        visible={showFeedback}
        title="Feedback & Bug Reporting"
        onCancel={() => onChangeShowFeedback(false)}
      >
        <div>
          <div className={styles.feedbackSection}>
            <Typo className={styles.feedbackText}>
              We would be extremely appreciative if you would be willing to take
              the time to report to us where we have messed up. Feel free to
              include comments on what you like as well!
            </Typo>
            <Typo className={styles.feedbackText}>
              <>
                When providing feedback, please include the following
                information in your email:
                <ol>
                  <li>First and Last Name</li>
                  <li>Your university</li>
                  <li>
                    Section and Subsection of where your concern is located. A
                    screen shot would be really helpful
                  </li>
                  <li>Title of concern</li>
                  <li>Details about concern</li>
                </ol>
              </>
            </Typo>
            <Typo className={styles.feedbackText}>
              Thank you so much! We can make the software better for you with
              your help!
            </Typo>
          </div>
          <Typo className={styles.feedbackTitle} semi>
            Support
          </Typo>
          <Typo className={styles.feedbackText}>
            Our company currently doesn't have any employees. We are a very
            small software service seeking your help to grow the platform.
            Because of our small size, we are unable to provide individual
            support for specific grant related issues (although we wish we
            could!). We aim to continue to improve Budget Mentor to make it
            better at anticipating your questions and future needs!
          </Typo>
          <Typo className={styles.feedbackText}>
            <>
              If you are having questions with grant related issues, we
              encourage you to follow the steps below to problem solve your
              specific issue efficiently:
              <ol>
                <li>
                  If you are part of an organization that provides support on
                  grant related questions, we encourage you to contact them with
                  your question.
                </li>
                <li>
                  It may be helpful to check the NSF's PAPPG guidelines for the
                  most recent year. Here is a link to the budget section of
                  their guidelines.
                </li>
                <li>
                  If you are finding a section of our software unintuitive, or
                  are having a questions about using a section of our software,
                  we are deeply in need of your help. We ask you to:
                  <ol>
                    <li>Please email us with your concern.</li>
                    <li>
                      Please use the term "Budget Mentor" and the name of the
                      section you are having trouble navigating in your search
                      engine.
                    </li>
                    <li>
                      Please help the community by posting in any Budget Mentor
                      forum thread you find with the solution that best worked
                      for you (and the date your solution worked).
                    </li>
                  </ol>
                </li>
              </ol>
            </>
          </Typo>
          <Typo className={styles.feedbackText}>
            Thank you so much for using and supporting our software. We are
            deeply appreciative.
          </Typo>
        </div>
      </Modal>
      <Modal
        visible={showPaywall}
        small
        icon="lock-unlocked-01"
        iconClassName={styles.lock}
        iconType="info"
        onCancel={onHidePaywall}
      >
        <div className={styles.lockModal}>
          <Typo className={styles.lockText}>
            We appreciate your interest in Budget Mentor. Please upgrade your
            subscription to unlock this and other sections
          </Typo>
          <div className={styles.lockFooter}>
            <Button
              className={styles.button}
              type="bordered"
              size="lg"
              name="cancel-lock"
              onClick={onHidePaywall}
            >
              Cancel
            </Button>
            <Link
              className={styles.button}
              to={`/settings/account/billing`}
              onClick={onHidePaywall}
            >
              <Button size="lg" name="subscribe-lock">
                Subscribe
              </Button>
            </Link>
          </div>
        </div>
      </Modal>
      {editable?.id ? (
        <Settings
          visible={showSettingsModal}
          onCancel={handleCloseSettings}
          grantId={editable.id}
        />
      ) : null}
    </div>
  );
};

export default Paper;
