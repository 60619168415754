import { useState, useCallback } from "react";
import { AxiosResponse, AxiosError } from "axios";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import toast from "components/toast";
import {
  trackUserAction,
  identifyUserForTracking,
  setUserTrackingData,
  stopUserTracking,
} from "helpers/trackUserActions";
import {
  authTokenSelector,
  authUserSelector,
  authPlansSelector,
} from "store/auth/selector";
import {
  setUser,
  setToken,
  clearAuth,
  setPlansActions,
} from "store/auth/actions";
import { clearAll } from "store/grants/actions";
import { clearUI } from "store/ui/actions";
import { Organization, Plan, User } from "store/auth/types";
import { tokenKey } from "const";
import parseErrors, { Errors } from "helpers/errors";
import useApi from "./useApi";
import { confirm } from "components/confirmation";
import * as T from "./requestTypes";
import useUI from "./useUI";
import { useMixPanel } from "./useMixPanel";

type iUseAuth = {
  login: (data: T.LoginData, remember: boolean) => void;
  forgot: (data: T.ForgotData, silent?: boolean) => void;
  reset: (data: T.ResetPasswordData) => void;
  resendEmail: (data: T.ResendEmail, message: string) => void;
  confirmEmail: (data: T.ConfirmEmail, cb: () => void) => void;
  register: (data: T.RegisterData, cb: () => void) => void;
  validateRegisterForm: (data: T.ValidateRegisterData, cb: () => void) => void;
  errors: Errors;
  paid: boolean;
  handleChangeErrors: (errors: Errors) => void;
  logout: () => void;
  updatePassword: (data: T.UpdatePasswordData, cb: () => void) => void;
  updateMe: (data: T.UpdateUserInfo, cb?: (data: User) => void) => void;
  updateOrganization: (data: T.OrganizationPureUserData) => void;
  requestSupport: (data: T.RequestSupportData, cb: () => void) => void;
  getPlans: () => void;
  me: () => void;
  socialLogin: (type: "fb" | "google") => void;
  socialLoginWithToken: (type: "fb" | "google", token: string) => void;
  onSuccessOAuth: (code: string, social: "ms" | "google") => void;
  token: string;
  user: User;
  organization: Organization;
  loading: boolean;
  paymentProcessing: boolean;
  plans: Plan[];
  checkout: (productId: string) => void;
  changePlan: (productId: string) => void;
  managePlan: () => void;
  softLogin: () => void;
  getAwardedGrant: () => void;
  resetSuccess: boolean;
  googleLoading: boolean;
  msLoading: boolean;
  msLogin: () => void;

  awardedGrant: AwardedGrant | null;
  emailConfirmed: boolean | undefined;
};

export type AwardedGrant = {
  id: string;
  title: string;
  budgetAmount: number;
};

const useAuth = (): iUseAuth => {
  const {
    signedOut,
    signedIn,
    personalInformationUpdated,
    organizationInformationSaved,
    emailVerified,
  } = useMixPanel();
  const dispatch = useDispatch();
  const api = useApi();
  const { loader } = useUI();
  const user = useSelector(authUserSelector);
  const token = useSelector(authTokenSelector);
  const plans = useSelector(authPlansSelector);
  const { search } = useLocation();
  const history = useHistory();

  const [errors, onChangeErrors] = useState<Errors>(parseErrors());
  const [awardedGrant, onChangeAwardedGrant] = useState<null | AwardedGrant>(
    null
  );
  const [loading, onChangeLoading] = useState<boolean>(false);
  const [googleLoading, onChangeGoogleLoading] = useState<boolean>(false);
  const [msLoading, onChangeMSLoading] = useState<boolean>(false);
  const [emailConfirmed, onChangeEmailConfirmed] = useState<
    boolean | undefined
  >(undefined);
  const [resetSuccess, onChangeResetSuccess] = useState<boolean>(false);

  const forgot = useCallback(
    (data: T.ForgotData, silent?: boolean) => {
      onChangeErrors({});
      onChangeLoading(true);
      api
        .forgot(data)
        .then(() => {
          onChangeLoading(false);
          if (!silent) history.push(`/forgot-success?email=${data.email}`);
          if (silent) {
            toast.success({
              title: "Check your email",
              message: "We sent a password reset link to your email",
            });
          }
        })
        .catch((error: AxiosError) => {
          onChangeLoading(false);
          if (error?.response?.data) {
            // @ts-ignore
            onChangeErrors(parseErrors(error.response.data?.errors));
          }
        });
    },
    [history, api]
  );

  const reset = useCallback(
    (data: T.ResetPasswordData) => {
      onChangeErrors({});
      onChangeLoading(true);
      api
        .reset(data)
        .then(() => {
          onChangeResetSuccess(true);
          onChangeLoading(false);
        })
        .catch((error: AxiosError) => {
          onChangeLoading(false);
          if (error?.response?.data) {
            // @ts-ignore
            onChangeErrors(parseErrors(error.response.data?.errors));
          }
        });
    },
    [api]
  );

  const register = useCallback(
    (data: T.RegisterData, cb: () => void) => {
      onChangeLoading(true);
      onChangeErrors({});
      api
        .register(data)
        .then((response: AxiosResponse) => {
          onChangeLoading(false);
          cb();
          identifyUserForTracking(response.data.id);
          setUserTrackingData({ email: response.data.email });
        })
        .catch((error: AxiosError) => {
          onChangeLoading(false);
          if (error?.response?.data) {
            // @ts-ignore
            onChangeErrors(parseErrors(error.response.data?.errors));
          }
        });
    },
    [api]
  );

  const validateRegisterForm = useCallback(
    (data: T.ValidateRegisterData, cb: () => void) => {
      onChangeErrors({});
      onChangeLoading(true);
      api
        .validateRegister(data)
        .then(() => {
          onChangeLoading(false);
          cb();
        })
        .catch((error: AxiosError) => {
          onChangeLoading(false);
          if (error?.response?.data) {
            // @ts-ignore
            onChangeErrors(parseErrors(error.response.data?.errors));
          }
        });
    },
    [api]
  );

  const resendEmail = useCallback(
    (data: T.ResendEmail, message: string) => {
      api
        .resendEmail(data)
        .then(() => {
          toast.success({
            title: "Check your email",
            message,
          });
        })
        .catch(() => {
          toast.success({
            title: "Error",
            message: "Something went wrong",
          });
        });
    },
    [api]
  );

  const confirmEmail = useCallback(
    (data: T.ConfirmEmail, cb: () => void) => {
      onChangeLoading(true);
      api
        .confirmEmail(data)
        .then(() => {
          onChangeEmailConfirmed(true);
          onChangeLoading(false);
          toast.success({
            title: "Your account has been verified!",
            message: "Please log in to continue using the app",
          });
          emailVerified();
          cb();
        })
        .catch(() => {
          onChangeLoading(false);
          onChangeEmailConfirmed(false);
        });
    },
    [emailVerified, api]
  );

  const getPlans = useCallback(() => {
    loader.start();
    api
      .getPlans()
      .then((response: AxiosResponse) => {
        dispatch(setPlansActions(response.data ?? []));
      })
      .finally(() => {
        loader.stop();
      });
  }, [api, dispatch, loader]);

  const logout = useCallback(() => {
    dispatch(clearAuth());
    dispatch(clearAll());
    dispatch(clearUI());
    localStorage.clear();
    sessionStorage.clear();
    stopUserTracking();
    history.push("/login");
    signedOut();
  }, [dispatch, history]);

  const me = useCallback(
    (tokenFromLogin?: string) => {
      if (token || tokenFromLogin) {
        api
          .me(tokenFromLogin)
          .then((response: AxiosResponse) => {
            dispatch(setUser(response.data));
            getPlans();
            const paymentSuccess =
              response.data.billing?.stripe?.paymentProcessStatus === "success";
            if (paymentSuccess) {
              confirm({
                title: "Thank You!",
                text: "Your payment was successful",
                type: "success",
                hideCancel: true,
                icon: "check",
                okText: "Continue",
                onConfirm: () => api.noticeConfirmPayment(),
              });
              return;
            }
          })
          .catch(logout);
      }
    },
    [dispatch, getPlans, api, logout, token]
  );

  const login = useCallback(
    (data: T.LoginData, remember: boolean) => {
      onChangeErrors({});
      onChangeLoading(true);
      api
        .login(data)
        .then((response: AxiosResponse) => {
          onChangeLoading(false);
          const storage = remember ? localStorage : sessionStorage;
          storage.setItem(tokenKey, response.data.token);
          dispatch(setUser(response.data.user));
          dispatch(setToken(response.data.token));
          me(response.data.token);
          identifyUserForTracking(response.data.user.id);
          setUserTrackingData({
            firstName: response.data.user.firstName,
            lastName: response.data.user.lastName,
            email: response.data.user.email,
          });
          trackUserAction("Logged In");

          signedIn("email");

          const searchParams = new URLSearchParams(search);
          const url = searchParams.get("url");

          const urlToRedirect = url ? url : "/grants";

          console.log("url to redirect", urlToRedirect);

          setTimeout(() => {
            history.push(urlToRedirect);
          }, 100);
        })
        .catch((error: AxiosError) => {
          onChangeLoading(false); // @ts-ignore
          if (error?.response?.data?.errors)
            onChangeErrors(
              parseErrors({
                //@ts-ignore
                ...error.response.data?.errors,
                password: "",
              })
            );
        });
    },
    [api, dispatch, me, signedIn, history]
  );

  const updateMe = useCallback(
    (data: T.UpdateUserInfo, cb?: (data: User) => void) => {
      loader.start();
      onChangeErrors({});
      onChangeLoading(true);
      api
        .updateMe(data)
        .then((response: AxiosResponse) => {
          personalInformationUpdated();
          onChangeLoading(false);
          loader.stop();
          dispatch(setUser(response.data));
          cb && cb(response.data);
          toast.success({
            title: "Account settings have been changed",
            message: "Changes have been successfully saved",
          });
        })
        .catch((error: AxiosError) => {
          onChangeLoading(false);
          loader.stop();
          if (error?.response?.data) {
            // @ts-ignore
            onChangeErrors(parseErrors(error.response.data?.errors));
          }
        });
    },
    [loader, api, personalInformationUpdated, dispatch]
  );

  const updateOrganization = useCallback(
    (data: T.OrganizationPureUserData) => {
      onChangeErrors({});
      onChangeLoading(true);
      loader.start();
      api
        .updateMe({ organization: data })
        .then((response: AxiosResponse) => {
          organizationInformationSaved();
          onChangeLoading(false);
          loader.stop();
          dispatch(setUser(response.data));
          toast.success({
            title: "Account settings have been changed",
            message: "Changes have been successfully saved",
          });
        })
        .catch((error: any) => {
          loader.stop();
          onChangeLoading(false); // @ts-ignore
          if (error?.response?.data?.errors?.organization) {
            // @ts-ignore
            onChangeErrors(
              parseErrors(error?.response?.data?.errors?.organization)
            );
          }
        });
    },
    [loader, api, organizationInformationSaved, dispatch]
  );

  const updatePassword = useCallback(
    (data: T.UpdatePasswordData, cb: () => void) => {
      if (data.password !== data.confirmPassword) {
        onChangeErrors({
          confirmPassword: ["Should be equal to new password"],
        });
        return;
      }
      onChangeLoading(true);
      loader.start();
      onChangeErrors({});
      api
        .updatePassword({
          oldPassword: data.oldPassword,
          password: data.password,
        })
        .then(() => {
          onChangeLoading(false);
          loader.stop();
          cb();
          toast.success({
            title: "Change password",
            message: "Your password has been changed",
          });
        })
        .catch((error: AxiosError) => {
          loader.stop();
          onChangeLoading(false); // @ts-ignore
          if (error?.response?.data?.errors) {
            // @ts-ignore
            onChangeErrors(parseErrors(error.response.data.errors));
          }
        });
    },
    [api, loader]
  );

  const handleChangeErrors = useCallback((errors: Errors) => {
    onChangeErrors(errors);
  }, []);

  const requestSupport = useCallback(
    (data: T.RequestSupportData, cb: () => void) => {
      api.requestSupport(data).then(() => {
        cb();
        toast.success({
          title: "The form has been submitted.",
          message: "Your feedback was successfully sent",
        });
      });
    },
    [api]
  );

  const checkout = useCallback(
    (productId: string) => {
      onChangeLoading(true);
      api.checkout(productId).then((response: AxiosResponse) => {
        onChangeLoading(false);
        window.location.href = response.data.url;
      });
      trackUserAction("Upgrade to Paid Plan Initiated");
    },
    [api]
  );

  const changePlan = useCallback(
    (productId: string) => {
      onChangeLoading(true);
      api.changePlan(productId).then((response: AxiosResponse) => {
        onChangeLoading(false);
      });
    },
    [api]
  );

  const managePlan = useCallback(() => {
    onChangeLoading(true);
    api.managePlan().then((response: AxiosResponse) => {
      onChangeLoading(false);
      window.location.href = response.data.url;
    });
    trackUserAction("Upgrade to Paid Plan Initiated");
  }, [api]);

  const getAwardedGrant = useCallback(() => {
    api.getAwardedGrant().then((response: AxiosResponse) => {
      onChangeAwardedGrant(response.data);
    });
  }, [api]);

  const softLogin = useCallback(() => {
    if (token) {
      api.me(token).then((response: AxiosResponse) => {
        dispatch(setUser(response.data));
        const paymentSuccess =
          !response.data.billing?.stripe?.isWaitingPaymentSuccess &&
          response.data.billing?.stripe?.paymentProcessStatus === "success";
        if (paymentSuccess) {
          confirm({
            title: "Thank You!",
            text: "Your payment was successful",
            type: "success",
            hideCancel: true,
            icon: "check",
            okText: "Continue",
            onConfirm: () => api.noticeConfirmPayment(),
          });
          return;
        }
        const paymentFailure =
          !response.data.billing?.stripe?.isWaitingPaymentSuccess &&
          response.data.billing?.stripe?.paymentProcessStatus === "fail";
        if (paymentFailure) {
          confirm({
            title: "Payment failed!",
            text: "It seems we have not received money",
            type: "error",
            hideCancel: true,
            okText: "Try again",
            onConfirm: () => history.push(`/settings/account/billing`),
          });
        }
      });
    }
  }, [dispatch, api, history, token]);

  const socialLogin = useCallback((type: "fb" | "google") => {
    if (type === "google") {
      window.location.href = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${process.env.REACT_APP_GOOGLE_CLIENT_ID}&redirect_uri=http://${window.location.host}/login&response_type=code&scope=https://www.googleapis.com/auth/userinfo.email`;
    }
  }, []);

  const socialLoginWithToken = useCallback(
    (type: "fb" | "google", token: string) => {
      if (type === "google") {
        api
          .socialLogin(type, { idToken: token })
          .then((response: AxiosResponse) => {
            localStorage.setItem(tokenKey, response.data.token);
            dispatch(setUser(response.data.user));
            dispatch(setToken(response.data.token));
            me(response.data.token);
            identifyUserForTracking(response.data.user.id);
            setUserTrackingData({
              firstName: response.data.user.firstName,
              lastName: response.data.user.lastName,
              email: response.data.user.email,
            });
            trackUserAction("Logged In");
            history.push("/grants");
          });
      }
    },
    [api, history, dispatch, me]
  );

  const onSuccessOAuth = (code: string, social: "google" | "ms") => {
    const changeLoading =
      social === "google" ? onChangeGoogleLoading : onChangeMSLoading;
    changeLoading(true);
    api
      .socialLogin(social, {
        code,
      })
      .then((response: AxiosResponse) => {
        localStorage.setItem(tokenKey, response.data.token);
        dispatch(setUser(response.data.user));
        dispatch(setToken(response.data.token));
        me(response.data.token);
        identifyUserForTracking(response.data.user.id);
        setUserTrackingData({
          firstName: response.data.user.firstName,
          lastName: response.data.user.lastName,
          email: response.data.user.email,
        });
        changeLoading(false);
        trackUserAction("Logged In");
        history.push("/grants");
        signedIn(social === "google" ? "google" : "microsoft");
      })
      .catch(() => changeLoading(false));
  };

  const msLogin = useCallback(() => {
    onChangeMSLoading(true);
    api
      .getMSAuthLink()
      .then((response: AxiosResponse) => {
        window.location.href = response.data;
        onChangeMSLoading(false);
      })
      .catch(() => {
        onChangeMSLoading(false);
      });
  }, [api]);

  const paid = Boolean(user.billing?.subscription?.isActive);
  const paymentProcessing = Boolean(
    user.billing?.stripe?.isWaitingPaymentSuccess
  );

  return {
    login,
    onSuccessOAuth,
    msLogin,
    msLoading,
    socialLoginWithToken,
    socialLogin,
    getAwardedGrant,
    managePlan,
    softLogin,
    requestSupport,
    paymentProcessing,
    changePlan,
    getPlans,
    updatePassword,
    checkout,
    reset,
    handleChangeErrors,
    plans,
    updateMe,
    updateOrganization,
    paid,
    googleLoading,
    awardedGrant,
    me,
    organization: user.organization,
    logout,
    confirmEmail,
    register,
    resendEmail,
    emailConfirmed,
    validateRegisterForm,
    forgot,
    resetSuccess,
    user,
    token,
    loading,
    errors,
  };
};

export default useAuth;
