import Modal from "components/modal";
import styles from "./invitationManageModal.module.css";
import Typo from "components/typo";
import { useMutation, useQuery, useQueryClient } from "react-query";
import client from "helpers/apiClient";
import { useRef, useState } from "react";
import {
  Check,
  ChevronLeft,
  ChevronRight,
  PencilIcon,
  PencilOff,
} from "lucide-react";
import toast from "components/toast";
import { confirm } from "components/confirmation";
import { usePagination } from "hooks/common/usePagination";

type ManageUserPermissionItem = {
  userId: string;
  firstName: string | undefined;
  lastName: string | undefined;
  email: string;
  usedGrantCount: number;
  totalGrantCount: number;
};

const NOT_SHOW_AGAIN_CONFIRM_MANAGE_MODAL =
  "NOT_SHOW_AGAIN_CONFIRM_MANAGE_MODAL";

export function InvitationManageModal({
  open,
  onOpenChanged,
}: {
  open: boolean;
  onOpenChanged: (a: boolean) => void;
}) {
  return (
    <Modal onCancel={() => onOpenChanged(false)} wide visible={open}>
      <InvitationManageTable />
    </Modal>
  );
}

function InvitationManageTable() {
  const { data: users } = useQuery({
    queryKey: ["tokens", "owner"],
    queryFn: async () => {
      const response = await client.get("/grant-tokens/owner");
      return response.data as ManageUserPermissionItem[];
    },
  });

  return (
    <div>
      <Typo className={styles.tabTitle} semi>
        Manage Permissions to bill grants with people from your organization
      </Typo>
      {users && <ManageTable users={users} />}
    </div>
  );
}

type UpdateTotalGrantType = {
  userId: string;
  numberOfPermissions: number;
};
function ManageTable({ users }: { users: ManageUserPermissionItem[] }) {
  const { pageItems, page, hasNext, hasPrevious, next, previous } =
    usePagination(users);
  const queryClient = useQueryClient();

  const updateTotalGrants = useMutation({
    mutationFn: async (body: UpdateTotalGrantType) => {
      await client.patch("/grant-tokens/update-permissions", body);
    },
    onSuccess: () => {
      toast.success("Successfully updated grant permissions");
      queryClient.invalidateQueries({ queryKey: ["tokens"] });
    },
    onError: () => {
      toast.error("Failed to update grant permissions");
    },
  });

  function handleUpdateTotalGrants(
    value: UpdateTotalGrantType,
    usedGrants: number
  ) {
    const notShowAgain =
      localStorage.getItem(NOT_SHOW_AGAIN_CONFIRM_MANAGE_MODAL) === "true";
    if (notShowAgain && value.numberOfPermissions < usedGrants) {
      confirm({
        title: "Modify Total Grants",
        text: "Are you sure you want to decrease the number of permissions on this account? This will lead to locked grants",
        type: "info",
        label: "Do not show again",
        okText: "Yes",
        onConfirm: (notShowAgain) => {
          if (notShowAgain) {
            localStorage.setItem(NOT_SHOW_AGAIN_CONFIRM_MANAGE_MODAL, "true");
          }
          updateTotalGrants.mutateAsync(value);
        },
      });
    } else {
      updateTotalGrants.mutateAsync(value);
    }
  }

  return (
    <div>
      <table className={styles.bmTable}>
        <thead>
          <tr>
            <td>Full Name</td>
            <td>Email</td>
            <td>Used Grants</td>
            <td>Total Grants</td>
          </tr>
        </thead>
        <tbody>
          {pageItems.map((user) => (
            <tr key={user.email}>
              <td>
                {getFullName(user.firstName, user.lastName) || (
                  <i>{"<not set>"}</i>
                )}
              </td>
              <td>{user.email}</td>
              <td>{user.usedGrantCount}</td>
              <td>
                <InlineTableNumberEditor
                  value={user.totalGrantCount}
                  onValueChanged={(value) =>
                    handleUpdateTotalGrants(
                      {
                        userId: user.userId,
                        numberOfPermissions: value,
                      },
                      user.usedGrantCount
                    )
                  }
                />
              </td>
            </tr>
          ))}
          {pageItems.length === 0 && (
            <tr>
              <td colSpan={4} style={{ textAlign: "center", padding: "30px" }}>
                No entries found.
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <div className={styles.pagination}>
        <button
          onClick={previous}
          disabled={!hasPrevious}
          className={styles.paginationButton}
        >
          <ChevronLeft className={styles.paginationButtonIcon} />
          <span>Previous</span>
        </button>
        <span>Page {page}</span>
        <button
          onClick={next}
          disabled={!hasNext}
          className={styles.paginationButton}
        >
          <span>Next</span>
          <ChevronRight className={styles.paginationButtonIcon} />
        </button>
      </div>
    </div>
  );
}

function InlineTableNumberEditor({
  value: defaultValue,
  onValueChanged,
}: {
  value: number;
  onValueChanged: (v: number) => void;
}) {
  const [value, setValue] = useState(defaultValue);
  const [editMode, setEditMode] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  if (!editMode) {
    return (
      <div className={styles.inlineEditNoEdit}>
        <span>{value}</span>
        <div
          className={styles.iconButton}
          onClick={() => {
            setEditMode(true);
            setTimeout(() => {
              inputRef.current?.focus();
            }, 50);
          }}
        >
          <PencilIcon />
        </div>
      </div>
    );
  }
  return (
    <div className={styles.inlineEdit}>
      <input
        ref={inputRef}
        className={styles.inlineEditInput}
        value={value}
        onChange={(e) => setValue(+e.target.value)}
      />
      <div className={styles.iconContainer}>
        <div
          className={styles.iconButton}
          onClick={() => {
            setEditMode(false);
            onValueChanged(value);
          }}
        >
          <Check />
        </div>
        <div className={styles.iconButton} onClick={() => setEditMode(false)}>
          <PencilOff />
        </div>
      </div>
    </div>
  );
}

function getFullName(
  firstName: string | undefined,
  lastName: string | undefined
) {
  return `${firstName || ""} ${lastName || ""}`.trim();
}
